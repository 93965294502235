<template>
    <div></div>
</template>
<script>
export default {
    data(){
        return{

        }
    },
    created(){
        const result = this.getCode()
        console.log(result)
    },
    methods:{
        getCode(){
            var url = location.hash
            var theRequest = new Object()
            if (url.indexOf("?") != -1) {
                var str = url.substr(1)
                var strs = str.split("&")
                for(var i = 0; i < strs.length; i ++) {
                    theRequest[strs[i].split("=")[0]]=(strs[i].split("=")[1])
                }
            }
            return theRequest
        }
    }
}
</script>
<style lang="scss" scoped>


</style>